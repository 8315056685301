/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, FormControlLabel, MenuItem, Select, Typography } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';

import {
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState
} from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { Button, InputAdornment, Paper, TableRow, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SelectedCommentAndNotesComponent from './selectedCommentAndNotes.component';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { deleteCommentsAndNotes } from 'modules/authorizations/store/actions/commentsAndNotes.actions';
import { DELETE_COMMENT_AND_NOTES_FAILURE } from 'modules/authorizations/store/types/commentsAndNotesTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import {
  ExceptionCategoryOptions,
  ExceptionCodeOptions,
  ExceptionRequestTypeOptions,
  ExceptionStatusOptions,
  RegardingOptions,
  SearchableProperties,
  ServiceOptions
} from './commentAndNotesConstants';

export const formatDate = date => {
  if (!date) return '';
  try {
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) return '';

    // Format YYYY-MM-DD HH:MM:SS
    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
    const day = String(parsedDate.getDate()).padStart(2, '0');
    const hours = String(parsedDate.getHours()).padStart(2, '0');
    const minutes = String(parsedDate.getMinutes()).padStart(2, '0');
    const seconds = String(parsedDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } catch {
    return '';
  }
};

export const createDefaultComment = (authorizationId, serviceOptionId, localStorage, regardingOptionId = null, createdParty = 'Internal - Manual') => {
  return {
    authorizationId: authorizationId,
    commentId: 0,
    addedBy: localStorage.getItem('username') || '',
    createdParty: createdParty,
    createdDate: new Date().toISOString(),
    modifiedBy: localStorage.getItem('username') || '',
    serviceId: serviceOptionId,
    regardingId: regardingOptionId,
    transfereeViewable: false,
    clientViewable: false,
    pinnedComment: false,
    subject: null,
    commentContent: null,
    exceptionReason: null,
    exceptionRequestedMoney: null,
    exceptionCategoryId: null,
    exceptionCodeId: null,
    exceptionRequestTypeId: null,
    exceptionApprovedDate: null,
    exceptionActualAmount: null,
    exceptionApprovedAmount: null,
    exceptionApprovedBy: null
  };
};

const customFilterCell = props => {
  if (props.column?.type === 'dropdown') {
    return (
      <TableFilterRow.Cell {...props}>
        <Select
          value={props.filter ? props.filter.value : ''}
          onChange={event => {
            const value = event.target.value;
            props.onFilter(value ? { value, operation: 'equal' } : null);
          }}
          displayEmpty
          fullWidth
          variant="standard"
          sx={{ fontSize: '0.75rem' }}
        >
          <MenuItem value="" sx={{ fontSize: '0.75rem' }}>
            All
          </MenuItem>
          {props.column.options.map(option => (
            <MenuItem key={option.id} value={option.title} sx={{ fontSize: '0.75rem' }}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </TableFilterRow.Cell>
    );
  }

  return <TableFilterRow.Cell {...props} />;
};

const createLookupMap = arr =>
  arr.reduce((acc, item) => {
    acc[item.id] = item.title;
    return acc;
  }, {});

const CommentsAndNotes = props => {
  const { authorizationId, details, viewMode = 'fullView', serviceOptionId, regardingOptionId } = props;
  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING
    }
  });

  const serviceLookup = createLookupMap(ServiceOptions);
  const regardingLookup = createLookupMap(RegardingOptions);
  const exceptionCategoryLookup = createLookupMap(ExceptionCategoryOptions);
  const exceptionStatusLookup = createLookupMap(ExceptionStatusOptions);
  const exceptionCodeLookup = createLookupMap(ExceptionCodeOptions);
  const exceptionRequestTypeLookup = createLookupMap(ExceptionRequestTypeOptions);

  let allNotes = details.commentsAndNotes ? details.commentsAndNotes : [];
  const [isLoading, setIsLoading] = useState(false);
  const [isNewComment, setIsNewComment] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [showExpenseDetails, setShowExpenseDetails] = useState(regardingOptionId === 23);

  const onClickNewComment = () => {
    setIsNewComment(true);
    setSelectedComment(createDefaultComment(authorizationId, serviceOptionId, localStorage));
  };
  useEffect(() => {
    if (viewMode === 'fullView' && allNotes.length > 0) {
      const filteredNotes = regardingOptionId ? allNotes.filter(note => note.regardingId === regardingOptionId) : allNotes;
      if (filteredNotes.length === 0) {
        setSelectedComment(null);
        return;
      }
      const newestComment = filteredNotes.reduce((latest, current) => {
        return new Date(current.createdDate) > new Date(latest.createdDate) ? current : latest;
      });

      setSelectedComment(newestComment);
    }
  }, []);

  useEffect(() => {
    allNotes = details.commentsAndNotes ? [...details.commentsAndNotes] : [];
    allNotes.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
  }, [details.commentsAndNotes]);

  const onDeleteComment = async commentId => {
    setIsLoading(true);
    try {
      const resp = await props.deleteCommentsAndNotes(commentId);
      if (resp.type === DELETE_COMMENT_AND_NOTES_FAILURE) {
        props.showToast('Failed to delete comment, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
      } else {
        if (commentId === selectedComment?.commentId) {
          setSelectedComment(null);
        }
      }
    } catch {
      props.showToast('Failed to delete comment, please try again later', TOAST_MESSAGE_SEVERITY_ERROR);
    }
    setIsLoading(false);
  };

  const onRowClick = rowData => {
    setIsNewComment(false);
    setSelectedComment(rowData);
  };

  useEffect(() => {
    if (!filterText) {
      setFilteredRows(allNotes);
    } else {
      handleFilterRowsChange();
    }
  }, [allNotes]);

  const renderHeaderRow = row => {
    return (
      <TableRow sx={{ backgroundColor: '#41B7C0', '& .MuiButtonBase-root': { color: 'white !important' } }}>
        {row.children.map(c => {
          return c;
        })}
      </TableRow>
    );
  };

  const [sorting, setSorting] = useState([{ columnName: 'date', direction: 'desc' }]);

  const baseTableColumnExtensions = [
    { columnName: 'serviceId', width: 160 },
    { columnName: 'regardingId', width: 170 },
    { columnName: 'createdDate', width: 135 },
    { columnName: 'createdParty', width: 90 },
    { columnName: 'attachmentNames', width: 90 }
  ];

  const expenseTableColumnExtensions = [
    { columnName: 'serviceId', width: 150 },
    { columnName: 'regardingId', width: 150 },
    { columnName: 'exceptionRequestedMoney', width: 110 },
    { columnName: 'exceptionApprovedAmount', width: 110 },
    { columnName: 'createdDate', width: 110 },
    { columnName: 'createdParty', width: 90 },
    { columnName: 'attachmentNames', width: 90 }
  ];

  const baseColumns = [
    {
      name: 'serviceId',
      title: 'Service',
      getCellValue: row => ServiceOptions.find(opt => opt.id === row.serviceId)?.title || '',
      type: 'dropdown',
      options: ServiceOptions
    },
    {
      name: 'regardingId',
      title: 'Regarding',
      getCellValue: row => RegardingOptions.find(opt => opt.id === row.regardingId)?.title || '',
      type: 'dropdown',
      options: RegardingOptions
    },
    { name: 'createdDate', title: 'Created Date', getCellValue: row => formatDate(row.createdDate) },
    { name: 'createdParty', title: 'Created Party' },
    { name: 'attachmentNames', title: 'Attachments' }
  ];

  const formatCurrency = amount => {
    if (amount == null || isNaN(amount)) return '-';
    return `$${parseFloat(amount).toFixed(2)}`;
  };

  const expenseDetailColumns = [
    {
      name: 'serviceId',
      title: 'Service',
      getCellValue: row => ServiceOptions.find(opt => opt.id === row.serviceId)?.title || '',
      type: 'dropdown',
      options: ServiceOptions
    },
    {
      name: 'regardingId',
      title: 'Regarding',
      getCellValue: row => RegardingOptions.find(opt => opt.id === row.regardingId)?.title || '',
      type: 'dropdown',
      options: RegardingOptions
    },
    {
      name: 'exceptionRequestedMoney',
      title: 'Requested Amount',
      getCellValue: row => formatCurrency(row.exceptionRequestedMoney)
    },
    {
      name: 'exceptionApprovedAmount',
      title: 'Approved Amount',
      getCellValue: row => formatCurrency(row.exceptionApprovedAmount)
    },
    { name: 'createdDate', title: 'Created Date', getCellValue: row => formatDate(row.createdDate) },
    { name: 'createdParty', title: 'Created Party' },
    { name: 'attachmentNames', title: 'Attachments' }
  ];

  const columns = showExpenseDetails ? expenseDetailColumns : baseColumns;
  const tableColumnExtensions = showExpenseDetails ? expenseTableColumnExtensions : baseTableColumnExtensions;

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [pageSizes] = useState([15, 20, 25]);
  const [filters, setFilters] = useState(
    serviceOptionId
      ? [{ columnName: 'serviceId', value: ServiceOptions.find(s => s.id === serviceOptionId)?.title }]
      : regardingOptionId
      ? [{ columnName: 'regardingId', value: RegardingOptions.find(s => s.id === regardingOptionId)?.title }]
      : []
  );
  const [filterText, setFilterText] = useState('');
  const [filteredRows, setFilteredRows] = useState(allNotes);

  useEffect(() => {
    setFilteredRows(handleFilterRowsChange());
  }, [filterText]);

  const handleFilterChange = e => {
    setFilterText(e.target.value);
  };

  const handleFilterRowsChange = () => {
    const searchText = filterText.trim().toLowerCase();
    if (!searchText) return allNotes;

    return allNotes.filter(note =>
      Object.entries(note).some(([key, value]) => {
        if (!SearchableProperties.has(key) || value == null) return false;

        let stringValue = value
          .toString()
          .trim()
          .toLowerCase();

        if (key === 'serviceId' && serviceLookup[value]) {
          stringValue = serviceLookup[value].toLowerCase();
        } else if (key === 'regardingId' && regardingLookup[value]) {
          stringValue = regardingLookup[value].toLowerCase();
        } else if (key === 'exceptionCategoryId' && exceptionCategoryLookup[value]) {
          stringValue = exceptionCategoryLookup[value].toLowerCase();
        } else if (key === 'exceptionStatusId' && exceptionStatusLookup[value]) {
          stringValue = exceptionStatusLookup[value].toLowerCase();
        } else if (key === 'exceptionCodeId' && exceptionCodeLookup[value]) {
          stringValue = exceptionCodeLookup[value].toLowerCase();
        } else if (key === 'exceptionRequestTypeId' && exceptionRequestTypeLookup[value]) {
          stringValue = exceptionRequestTypeLookup[value].toLowerCase();
        }

        return stringValue.includes(searchText);
      })
    );
  };

  const iconAdornment = {
    startAdornment: (
      <InputAdornment position="start">
        <FontAwesomeIcon size="1x" icon={['fas', 'fa-magnifying-glass']} color="gray" />
      </InputAdornment>
    )
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <FullscreenSpinner />}
      {!isLoading && viewMode === 'fullView' && (
        <Box display="flex" width="100%" height="100%">
          <Box sx={{ padding: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <TextField
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon size="1x" icon={['fas', 'magnifying-glass']} color="gray" />
                    </InputAdornment>
                  )
                }}
                sx={{
                  width: '350px',
                  paddingBottom: '5px'
                }}
                value={filterText}
                onChange={e => handleFilterChange(e)}
              />
              <Button color="primary" variant="contained" sx={{ height: '36px' }} onClick={onClickNewComment}>
                Create Comment
              </Button>
            </Box>
            <Box sx={{ marginTop: '4px', display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showExpenseDetails}
                    onChange={() => setShowExpenseDetails(prev => !prev)}
                    color="primary"
                    size="small"
                  />
                }
                label={<Typography sx={{ fontSize: '0.75rem' }}>Show Exception Details</Typography>}
                sx={{ marginLeft: '5px' }}
              />
            </Box>
            <Paper>
              <Grid rows={filteredRows} columns={columns}>
                <SortingState sorting={sorting} onSortingChange={setSorting} />
                <IntegratedSorting />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <FilteringState filters={filters} onFiltersChange={setFilters} />
                <IntegratedFiltering />
                <IntegratedPaging />
                <Table
                  columnExtensions={tableColumnExtensions}
                  cellComponent={props => {
                    const { column, row, ...restProps } = props;
                    if (column.name === 'serviceId') {
                      return (
                        <Table.Cell {...restProps}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <IconButton
                              color="error"
                              onClick={e => {
                                e.stopPropagation();
                                onDeleteComment(row.commentId);
                              }}
                              sx={{
                                marginLeft: '-20px'
                              }}
                            >
                              <Delete />
                            </IconButton>
                            <Typography
                              sx={{
                                fontSize: 'inherit',
                                color: 'inherit',
                                lineHeight: 1.5,
                                marginLeft: '-15px'
                              }}
                            >
                              {ServiceOptions.find(opt => opt.id === row.serviceId)?.title || ''}
                            </Typography>
                          </Box>
                        </Table.Cell>
                      );
                    }
                    return <Table.Cell {...props} />;
                  }}
                  rowComponent={props => {
                    const rowStyle = {
                      cursor: 'pointer',
                      backgroundColor: 'white'
                    };

                    const hoverStyle = {
                      backgroundColor: '#f5f5f5',
                      color: '#000'
                    };

                    return (
                      <Table.Row
                        {...props}
                        onClick={() => onRowClick(props.row)}
                        style={rowStyle}
                        onMouseEnter={e => {
                          Object.assign(e.currentTarget.style, hoverStyle);
                        }}
                        onMouseLeave={e => {
                          Object.assign(e.currentTarget.style, rowStyle);
                        }}
                      />
                    );
                  }}
                />
                <TableHeaderRow rowComponent={renderHeaderRow} showSortingControls />
                <TableFilterRow cellComponent={customFilterCell} />
                <PagingPanel pageSizes={pageSizes} />
              </Grid>
            </Paper>
          </Box>
          <Box flex={1}>
            <SelectedCommentAndNotesComponent
              selectedComment={selectedComment}
              setSelectedComment={setSelectedComment}
              setIsLoading={setIsLoading}
              authorizationId={authorizationId}
              isNewComment={isNewComment}
              setIsNewComment={setIsNewComment}
            />
          </Box>
        </Box>
      )}
      {!isLoading && viewMode !== 'fullView' && (
        <Box display="flex" width="100%" height="100%">
          {!selectedComment && (
            <Box sx={{ padding: 3 }}>
              <Box sx={{ flexDirection: 'row' }}>
                <TextField
                  diabled={true}
                  placeholder="Search..."
                  InputProps={iconAdornment}
                  sx={{ paddingBottom: '10px' }}
                  value={filterText}
                  onChange={e => handleFilterChange(e)}
                />
                <Button color="primary" variant="contained" sx={{ marginLeft: '10px', marginTop: '5px' }} onClick={onClickNewComment}>
                  Create Comment
                </Button>
              </Box>
              <Paper>
                <Grid rows={filteredRows} columns={columns}>
                  <SortingState sorting={sorting} onSortingChange={setSorting} />
                  <IntegratedSorting />
                  <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                  <FilteringState filters={filters} onFiltersChange={setFilters} />
                  <IntegratedFiltering />
                  <IntegratedPaging />
                  <Table
                    columnExtensions={tableColumnExtensions}
                    cellComponent={props => {
                      const { column, row, ...restProps } = props;
                      if (column.name === 'serviceId') {
                        return (
                          <Table.Cell {...restProps}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              <IconButton
                                color="error"
                                onClick={e => {
                                  e.stopPropagation();
                                  onDeleteComment(row.commentId);
                                }}
                                sx={{
                                  marginLeft: '-20px'
                                }}
                              >
                                <Delete />
                              </IconButton>
                              <Typography
                                sx={{
                                  fontSize: 'inherit',
                                  color: 'inherit',
                                  lineHeight: 1.5,
                                  marginLeft: '-15px'
                                }}
                              >
                                {ServiceOptions.find(opt => opt.id === row.serviceId)?.title || ''}
                              </Typography>
                            </Box>
                          </Table.Cell>
                        );
                      }
                      return <Table.Cell {...props} />;
                    }}
                    rowComponent={props => {
                      const rowStyle = {
                        cursor: 'pointer',
                        backgroundColor: 'white'
                      };

                      const hoverStyle = {
                        backgroundColor: '#f5f5f5',
                        color: '#000'
                      };

                      return (
                        <Table.Row
                          {...props}
                          onClick={() => onRowClick(props.row)}
                          style={rowStyle}
                          onMouseEnter={e => {
                            Object.assign(e.currentTarget.style, hoverStyle);
                          }}
                          onMouseLeave={e => {
                            Object.assign(e.currentTarget.style, rowStyle);
                          }}
                        />
                      );
                    }}
                  />
                  <TableHeaderRow rowComponent={renderHeaderRow} showSortingControls />
                  <TableFilterRow cellComponent={customFilterCell} />
                  <PagingPanel pageSizes={pageSizes} />
                </Grid>
              </Paper>
            </Box>
          )}
          {selectedComment && (
            <Box flex={1}>
              <SelectedCommentAndNotesComponent
                selectedComment={selectedComment}
                setSelectedComment={setSelectedComment}
                setIsLoading={setIsLoading}
                authorizationId={authorizationId}
                isNewComment={isNewComment}
                setIsNewComment={setIsNewComment}
                halfView={true}
              />
            </Box>
          )}
        </Box>
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({});

export default compose(connect(mapStateToProps, { deleteCommentsAndNotes }))(CommentsAndNotes);
