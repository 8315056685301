import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as firebase from 'firebase';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import MegaMenu from 'modules/common/MegaMenu.component';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import RouterBreadcrumbs from 'modules/common/breadcrumbs.component';
import classNames from 'classnames';

import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, Menu } from '@material-ui/icons';

import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import { GET_USER_PROFILE_SUCCESS } from 'modules/auth/types';
import { getUserProfile } from 'modules/auth/auth.actions';
import ActivityLogView from 'modules/authorizations/details/activityLogView.container';
import AuthorizationDetailsView from 'modules/authorizations/details/authorizationsDetailsView.container';
import AuthorizationsView from 'modules/authorizations/authorizations.container';
import ExpenseSummariesView from 'modules/expenses/expenses.container';
import ClientsDetailsView from 'modules/clients/details/clientDetailsView.container';
import ClientsView from 'modules/clients/clients.container';
import IntakeRecordDetailsView from 'modules/intake/details/IntakeRecordDetailsView.container';
import IntakeRecordsView from 'modules/intake/summary/intakeRecords.container';
import PasswordResetDialog from 'modules/auth/passwordResetDialog.component';
import auth from 'auth/auth';
import visitor from 'utilities/visitor';

import { GET_FIREBASE_TOKEN_SUCCESS } from 'index.types';
import { GET_RELOCATION_CONSULTANTS_FAILURE } from 'modules/common/types';
import { IMPORT_INTAKE_RECORD_SUCCESS } from 'modules/intake/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from './types';
import { TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { cachedWithRouteOnEnter } from 'modules/common/withRouteOnEnter.component';
import { getApiVersion, hideSideDrawer, showSideDrawer, showToast } from './layout.actions';
import { getFirebaseToken } from 'index.actions';
import { getIntakeSummaries, importIntakeRecord } from 'modules/intake/intake.actions';
import { getRelocationConsultants } from 'modules/common/common.actions';
import BenefitForm from 'modules/benefit/benefitForm.component';
import BenefitsContainer from 'modules/benefit/benefits.container';
import CandidateServicesView from 'modules/authorizations/details/candidateServices/candidateServicesView.container';
import DestinationServicesView from 'modules/authorizations/details/destinationServices/destinationServicesView.container';
import HouseholdGoodsView from 'modules/authorizations/details/hhg/householdGoodsView.container';
import ImportIntakeRecordDialog from 'modules/intake/importIntakeRecordDialog.component';
import ReferentialDataManagementView from 'modules/dataManagement/referentialDataManagement.container';
import SelfServiceContainer from 'modules/selfService/selfService.container';
import SelfServiceDetailsContainer from 'modules/selfService/details/selfServiceDetails.container';
import TaxAssistanceView from 'modules/authorizations/details/taxAssistanceView.container';
import withWidth from '@material-ui/core/withWidth';

import { Box, Button } from '@mui/material';
import ExpensesView from 'modules/authorizations/details/expenses/expensesView.container';
import GlobalView from 'modules/authorizations/details/global/globalView.container';
import HomesaleView from 'modules/authorizations/details/homesale/homesaleView.container';
import SettingsModal from 'modules/authorizations/details/settingsModal.component';
import TempLivingView from 'modules/authorizations/details/TemporaryLiving/tempLivingView.container';
import ExpenseDetailsView from 'modules/authorizations/details/expenses/details/expenseDetailsView.container';
import ExpenseLineItemDetailsView from 'modules/authorizations/details/expenses/details/expenseLineItemDetailsView.container';
import VouchersView from 'modules/vouchers/vouchers.container';
import voucherDetailsView from 'modules/vouchers/details/voucherDetailsView.container';
import VendorsView from 'modules/vendors/vendors.container';
import vendorsDetailsView from 'modules/vendors/details/vendorsDetailsView.container';
import vendorHistoryView from 'modules/vendors/details/vendorHistoryView.container';
import CommentsAndNotesView from 'modules/authorizations/details/commentsAndNotes/commentsAndNotesView.container';

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH_OPEN,
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: DRAWER_WIDTH_OPEN,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    maxWidth: '230px !important'
  },
  drawerOpen: {
    width: DRAWER_WIDTH_OPEN,
    maxWidth: '230px !important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: DRAWER_WIDTH_CLOSED_XS,
    maxWidth: '230px !important',
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH_CLOSED
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  authorizationsToolbar: {
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'space-between',
    minWidth: '95%'
  },
  authorizationsToolbarMenuItems: {
    display: 'flex',
    justifyContent: 'row',
    marginTop: '7px'
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  menuIconRoot: {
    margin: 0,
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    minWidth: 'unset',
    width: theme.spacing(3) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5) + 1
    }
  },
  menuIconSelected: {
    color: theme.palette.primary.main
  },
  menuList: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '230px !important'
  }
});

const SESSION_RENEWAL_INTERVAL = 3600000; // one hour

class AuthenticatedRoutes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPasswordReset: false,
      passwordResetError: null,
      isSubmittingPasswordReset: false,
      showImport: false,
      importError: null
    };
    this.renewSessionHandle = null;
  }

  async componentDidMount() {
    // TODO handle failure case
    let { type, response } = await this.props.getUserProfile();
    if (type === GET_USER_PROFILE_SUCCESS) {
      visitor.set('uid', response.email);
      localStorage.setItem('username', response.moveTrackUsername);
    }
    await this.props.getApiVersion();
    await this.authenticateWithFirebase();
    this.initSessionTimers();
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.renewSessionWhenVisible, false);
    clearInterval(this.renewSessionHandle);
  }

  authenticateWithFirebase = async () => {
    const tokenResult = await this.props.getFirebaseToken();
    if (tokenResult.type === GET_FIREBASE_TOKEN_SUCCESS) {
      await firebase.auth().signInWithCustomToken(tokenResult.response);
    }
  };

  initSessionTimers = () => {
    document.addEventListener('visibilitychange', this.renewSessionWhenVisible, false);
    this.renewSessionHandle = setInterval(() => {
      this.renewSession();
    }, SESSION_RENEWAL_INTERVAL);
  };

  renewSessionWhenVisible = ({ target }) => {
    if (target.visibilityState === 'visible') {
      this.renewSession();
    }
  };

  renewSession = async () => {
    const { history } = this.props;
    const wasRenewed = await auth.renewSession();
    if (!wasRenewed) {
      history.replace('/login', {
        from: { pathname: history.pathname }
      });
    }
  };

  navigate = path => {
    this.props.history.push(path);
  };

  handlePasswordResetRequest = async () => {
    this.setState({ passwordResetError: null, isSubmittingPasswordReset: true });
    try {
      const { email } = this.props.profile;
      await auth.requestPasswordReset(email);
      this.setState({ isSubmittingPasswordReset: false, showPasswordReset: false });
      this.props.showToast('Success! Please check your email for reset instructions');
    } catch (err) {
      this.setState({
        isSubmittingPasswordReset: false,
        passwordResetError: 'Something went wrong, please try again'
      });
    }
  };

  handleImportRequest = async authId => {
    this.setState({ importError: null });

    const { type } = await this.props.importIntakeRecord(authId);
    if (type === IMPORT_INTAKE_RECORD_SUCCESS) {
      this.setState({ showImport: false });
      this.props.showToast('Successfully imported auth file!', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });

      if (auth.userHasRole('admin')) {
        let getConsultantsAction = await this.props.getRelocationConsultants();
        if (getConsultantsAction.type === GET_RELOCATION_CONSULTANTS_FAILURE) {
          this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      this.props.getIntakeSummaries();
    } else {
      this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    }
  };

  onRouteEnter = () => {
    const authorized = auth.isPathAuthorized(this.props.location.pathname);
    if (!authorized) {
      this.props.history.push('/');
    }
  };

  navigationItems = [
    {
      title: 'Intake Records',
      route: '/intakeRecords',
      icon: ['fas', 'right-to-bracket']
    },
    {
      title: 'Authorizations',
      route: '/authorizations',
      icon: ['far', 'id-badge']
    },
    {
      title: 'Expenses',
      route: '/expenses',
      icon: ['fas', 'money-bills']
    },
    {
      title: 'Vouchers',
      route: '/vouchers',
      icon: ['fas', 'money-check-alt']
    },
    {
      title: 'Vendors',
      route: '/vendors',
      icon: ['fas', 'address-book']
    },
    {
      title: 'Clients',
      route: '/clients',
      icon: ['fas', 'user-friends']
    },
    {
      title: 'Benefits',
      route: '/benefits',
      requiredPermission: 'selfService:write:benefits',
      icon: ['fas', 'ballot']
    },
    {
      title: 'Self Service',
      route: '/selfService',
      requiredPermission: 'selfService:read:auths',
      icon: ['fas', 'concierge-bell']
    },
    {
      title: 'Data Management',
      route: '/dataManagement',
      icon: ['fas', 'database']
    }
  ];

  render() {
    const {
      classes,
      theme,
      title,
      shouldShowSideDrawer,
      profile,
      isImportingRecord,
      apiVersion,
      location,
      currentAuthId,
      authorizationDetails
    } = this.props;
    const { showPasswordReset, passwordResetError, isSubmittingPasswordReset, showImport, importError } = this.state;

    const screenIsXl = this.props.width === 'xl';
    const sideDrawerAvailable = screenIsXl && shouldShowSideDrawer;
    let authName = authorizationDetails?.[currentAuthId]?.authDetails?.summary?.transfereeName || null;

    const friendlyLinks = [
      {
        link: '/authorizations',
        name: 'Authorizations'
      },
      {
        short: '/log',
        name: 'Activity Log'
      },
      {
        short: '/taxAssistance',
        name: 'Tax Assistance'
      },
      {
        short: '/destinationServices',
        name: 'Destination Services'
      },
      {
        short: '/candidateServices',
        name: 'Candidate Services'
      },
      {
        short: '/comments',
        name: 'Comments'
      },
      {
        short: '/hhg',
        name: 'Household Goods'
      },
      {
        short: '/tempLiving',
        name: 'Temporary Living'
      },
      {
        short: '/global',
        name: 'Global'
      },
      {
        short: '/homesale',
        name: 'Homesale'
      },
      {
        short: '/expenses',
        name: 'Expenses'
      },
      {
        short: '/vendors',
        name: 'Vendors'
      },
      {
        short: `${currentAuthId}`,
        name: authName ? `${authName} (${currentAuthId})` : currentAuthId
      }
    ];

    const expenseDetailsLinks = [
      {
        link: '/expenses',
        name: 'Expenses'
      }
    ];

    const quickLinksMenuTitle = [
      {
        name: 'Quick Links',
        color: 'white !important',
        variant: 'h6',
        fontSize: '1.0714285714285714rem !important',
        icon: ['fas', 'fa-bars']
      }
    ];

    const settingsLinks = [
      {
        link: '/activityCalendar',
        name: 'Activity Calendar'
      },
      {
        link: '/banking',
        name: 'Banking'
      },
      {
        link: '/cola',
        name: 'COLA'
      },
      {
        link: '/documents',
        name: 'Documents'
      },
      {
        link: '/family',
        name: 'Family'
      },
      {
        link: '/letters',
        name: 'Letters'
      },
      {
        link: '/mida',
        name: 'MIDA'
      },
      {
        link: '/Needs',
        name: 'Needs'
      },
      {
        link: '/otherServices',
        name: 'Other Services'
      },
      {
        link: '/referrals',
        name: 'Referrals'
      },
      {
        link: '/survey',
        name: 'Survey'
      },
      {
        link: '/tasks',
        name: 'Tasks'
      },
      {
        link: '/tenancy',
        name: 'Tenancy'
      },
      {
        link: '/userData',
        name: 'User Data'
      },
      {
        link: '/vendorPerformance',
        name: 'Vendor Performance'
      }
    ];

    const taxServiceQuickLinks = [
      {
        link: '/advances',
        name: 'Advances'
      },
      {
        link: '/expenses',
        name: 'Expenses'
      },
      {
        link: '/salary',
        name: 'Salary'
      },
      {
        link: '/travel',
        name: 'Travel'
      },
      {
        link: '/exceptions',
        name: 'Exceptions'
      },
      {
        link: '/budgets',
        name: 'Budgets'
      },
      {
        link: '/taxes',
        name: 'Taxes'
      },
      {
        link: '/custom',
        name: 'Custom'
      }
    ];

    const temporaryLivingLinks = [
      {
        link: '/letters',
        name: 'Letters'
      },
      {
        link: '/exceptions',
        name: 'Exceptions'
      }
    ];

    const resolveQuickLinks = () => {
      if (window.location.href.includes('taxAssistance')) {
        return taxServiceQuickLinks;
      } else if (window.location.href.includes('tempLiving')) {
        return temporaryLivingLinks;
      }

      return settingsLinks;
    };

    const showBackToVendorHistoryPage = () => {
      return this.props.history.location.state?.previousLocation || '' === 'vendorHistory';
    };

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: sideDrawerAvailable
          })}
        >
          {location.pathname.includes('/authorizations/') && (
            <>
              <Toolbar disableGutters={!sideDrawerAvailable}>
                {screenIsXl ? (
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.props.showSideDrawer}
                    className={classNames(classes.menuButton, {
                      [classes.hide]: sideDrawerAvailable
                    })}
                  >
                    <Menu />
                  </IconButton>
                ) : (
                  <div style={{ marginRight: 40 }} />
                )}
                <div className={classes.authorizationsToolbar}>
                  <RouterBreadcrumbs friendlyLinks={friendlyLinks} />
                  <div className={classes.authorizationsToolbarMenuItems}>
                    <Box
                      onClick={() => this.navigate(`/authorizations/${currentAuthId}/comments`)}
                      sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                    >
                      <Typography variant="h6" color="inherit" noWrap style={{ marginRight: '7px' }}>
                        Comments
                      </Typography>
                      <FontAwesomeIcon
                        size="1x"
                        icon={['fas', 'fa-comment']}
                        color="white"
                        style={{ marginTop: '6px', marginRight: '13px' }}
                      />
                    </Box>
                    <Box
                      onClick={() => this.navigate(`/authorizations/${currentAuthId}/log`)}
                      sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                    >
                      <Typography variant="h6" color="inherit" noWrap style={{ marginRight: '7px' }}>
                        Activity Log
                      </Typography>
                      <FontAwesomeIcon
                        size="1x"
                        icon={['fas', 'fa-clipboard']}
                        color="white"
                        style={{ marginTop: '6px', marginRight: '13px' }}
                      />
                    </Box>
                    <MegaMenu menuTitle={quickLinksMenuTitle} menuLinks={resolveQuickLinks()} />
                    {!location.pathname.includes(`/authorizations/${currentAuthId}/`) && (
                      <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
                          <SettingsModal authId={currentAuthId} authDetails={authorizationDetails} />
                        </Box>
                      </>
                    )}
                  </div>
                </div>
              </Toolbar>
            </>
          )}
          {location.pathname.includes('/vendors/') && (
            <>
              <Toolbar disableGutters={!sideDrawerAvailable}>
                {screenIsXl ? (
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.props.showSideDrawer}
                    className={classNames(classes.menuButton, {
                      [classes.hide]: sideDrawerAvailable
                    })}
                  >
                    <Menu />
                  </IconButton>
                ) : (
                  <div style={{ marginRight: 40 }} />
                )}
                <div className={classes.authorizationsToolbar}>
                  <RouterBreadcrumbs friendlyLinks={expenseDetailsLinks} />
                  <div className={classes.authorizationsToolbarMenuItems}>
                    <Box
                      onClick={() => this.navigate(`${location.pathname}/history`)}
                      sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
                    >
                      <Typography variant="h6" color="inherit" noWrap style={{ marginRight: '7px' }}>
                        History
                      </Typography>
                      <FontAwesomeIcon
                        size="1x"
                        icon={['fas', 'fa-clipboard']}
                        color="white"
                        style={{ marginTop: '6px', marginRight: '13px' }}
                      />
                    </Box>
                  </div>
                </div>
              </Toolbar>
            </>
          )}
          {!location.pathname.includes('/authorizations/') &&
            !location.pathname.includes('/expenses/') &&
            !location.pathname.includes('/vouchers/') &&
            !location.pathname.includes('/vendors/') && (
              <>
                <Toolbar disableGutters={!sideDrawerAvailable}>
                  {screenIsXl ? (
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={this.props.showSideDrawer}
                      className={classNames(classes.menuButton, {
                        [classes.hide]: sideDrawerAvailable
                      })}
                    >
                      <Menu />
                    </IconButton>
                  ) : (
                    <div style={{ marginRight: 40 }} />
                  )}
                  <Typography variant="h6" color="inherit" noWrap>
                    {title}
                  </Typography>
                </Toolbar>
              </>
            )}
          {!location.pathname.includes('/authorizations/') &&
            (location.pathname.includes('/expenses/') || location.pathname.includes('/vouchers/')) && (
              <>
                <Toolbar disableGutters={!sideDrawerAvailable}>
                  {screenIsXl ? (
                    <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                      onClick={this.props.showSideDrawer}
                      className={classNames(classes.menuButton, {
                        [classes.hide]: sideDrawerAvailable
                      })}
                    >
                      <Menu />
                    </IconButton>
                  ) : (
                    <div style={{ marginRight: 40 }} />
                  )}
                  <div className={classes.authorizationsToolbar}>
                    <RouterBreadcrumbs friendlyLinks={expenseDetailsLinks} />
                    {showBackToVendorHistoryPage() && (
                      <Box sx={{ marginLeft: '30px' }}>
                        <Button
                          color="secondary"
                          variant="contained"
                          sx={{
                            maxHeight: '40px',
                            marginLeft: '12px',
                            marginTop: '8px',
                            backgroundColor: '#ffffff',
                            '&:hover': { backgroundColor: '#faf8f2' },
                            color: '#080600'
                          }}
                          onClick={() => this.props.history.push(`/vendors/${this.props.history.location.state?.vendorId}/history`)}
                        >
                          Return to Vendor History
                        </Button>
                      </Box>
                    )}
                  </div>
                </Toolbar>
              </>
            )}
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: sideDrawerAvailable,
            [classes.drawerClose]: !sideDrawerAvailable
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: sideDrawerAvailable,
              [classes.drawerClose]: !sideDrawerAvailable
            })
          }}
          open={sideDrawerAvailable}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.props.hideSideDrawer}>{theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}</IconButton>
          </div>
          <Divider />
          <List classes={{ root: classes.menuList }}>
            <div>
              {this.navigationItems.map(item => {
                if (item.requiredPermission) {
                  if (!auth.userHasPermission(item.requiredPermission)) {
                    return null;
                  }
                }
                const selected = location.pathname && location.pathname.startsWith(item.route);
                return (
                  <Tooltip title={item.title} key={item.title} placement="right">
                    <ListItem selected={selected} button onClick={() => this.navigate(item.route)}>
                      <ListItemIcon classes={{ root: classes.menuIconRoot }} className={selected ? classes.menuIconSelected : ''}>
                        <FontAwesomeIcon icon={item.icon} size="3x" />
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItem>
                  </Tooltip>
                );
              })}
            </div>
            <div>
              {auth.userHasRole('admin') && (
                <Tooltip title="Import Auth File" placement="right">
                  <ListItem button onClick={() => this.setState({ showImport: true })}>
                    <ListItemIcon classes={{ root: classes.menuIconRoot }}>
                      <FontAwesomeIcon icon={['fas', 'file-import']} size="2x" />
                    </ListItemIcon>
                    <ListItemText primary="Import Auth File" />
                  </ListItem>
                </Tooltip>
              )}
              {profile && (
                <Tooltip title="Reset Password" placement="right">
                  <ListItem button onClick={() => this.setState({ showPasswordReset: true })}>
                    <ListItemIcon classes={{ root: classes.menuIconRoot }}>
                      <FontAwesomeIcon icon={['fas', 'key']} size="2x" />
                    </ListItemIcon>
                    <ListItemText primary="Reset Password" />
                  </ListItem>
                </Tooltip>
              )}
              <Tooltip title="Logout" placement="right">
                <ListItem button onClick={() => this.navigate('/logout')}>
                  <ListItemIcon classes={{ root: classes.menuIconRoot }}>
                    <FontAwesomeIcon icon={['fas', 'power-off']} size="2x" />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </Tooltip>
              <ListItem dense disableGutters>
                <ListItemText classes={{ root: 'text-center' }}>{process.env.REACT_APP_VERSION}</ListItemText>
              </ListItem>
              <ListItem dense disableGutters>
                <ListItemText classes={{ root: 'text-center' }}>{apiVersion}</ListItemText>
              </ListItem>
            </div>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/intakeRecords" component={IntakeRecordsView} />
            <Route exact path="/intakeRecords/:id" component={IntakeRecordDetailsView} />
            <Route exact path="/authorizations" component={AuthorizationsView} />
            <Route exact path="/authorizations/:id" component={AuthorizationDetailsView} />
            <Route exact path="/authorizations/:id/log" component={ActivityLogView} />
            <Route exact path="/authorizations/:id/taxAssistance" component={TaxAssistanceView} />
            <Route exact path="/authorizations/:id/tempLiving" component={TempLivingView} />
            <Route exact path="/authorizations/:id/destinationServices" component={DestinationServicesView} />
            <Route exact path="/authorizations/:id/candidateServices" component={CandidateServicesView} />
            <Route exact path="/authorizations/:id/hhg" component={HouseholdGoodsView} />
            <Route exact path="/authorizations/:id/global" component={GlobalView} />
            <Route exact path="/authorizations/:id/homesale" component={HomesaleView} />
            <Route exact path="/authorizations/:id/expenses" component={ExpensesView} />
            <Route exact path="/authorizations/:id/expenses/approved" component={ExpensesView} />
            <Route exact path="/authorizations/:id/expenses/:expenseId" component={ExpenseDetailsView} />
            <Route exact path="/authorizations/:id/expenses/approved/:expenseLineId" component={ExpenseLineItemDetailsView} />
            <Route exact path="/expenses" component={ExpenseSummariesView} />
            <Route exact path="/expenses/:expenseId" component={ExpenseDetailsView} />
            <Route exact path="/vouchers" component={VouchersView} />
            <Route exact path="/vouchers/:id" component={voucherDetailsView} />
            <Route exact path="/vendors" component={VendorsView} />
            <Route exact path="/vendors/:id" component={vendorsDetailsView} />
            <Route exact path="/vendors/:id/history" component={vendorHistoryView} />
            <Route exact path="/clients" component={ClientsView} />
            <Route exact path="/clients/:id" component={ClientsDetailsView} />
            <Route exact path="/benefits" component={cachedWithRouteOnEnter(BenefitsContainer, this.onRouteEnter)} />
            <Route exact path="/benefits/add" component={cachedWithRouteOnEnter(BenefitForm, this.onRouteEnter)} />
            <Route exact path="/benefits/:id/v/:versionId" component={cachedWithRouteOnEnter(BenefitForm, this.onRouteEnter)} />
            <Route exact path="/selfService" component={cachedWithRouteOnEnter(SelfServiceContainer, this.onRouteEnter)} />
            <Route exact path="/selfService/:authId" component={cachedWithRouteOnEnter(SelfServiceDetailsContainer, this.onRouteEnter)} />
            <Route exact path="/dataManagement" component={ReferentialDataManagementView} />
            <Route exact path="/authorizations/:id/comments" component={CommentsAndNotesView} />
            <Route
              exact
              path="/logout"
              render={() => {
                auth.logout();
                return null;
              }}
            />
            <Redirect from="*" to="/intakeRecords" />
          </Switch>
          {profile && (
            <PasswordResetDialog
              open={showPasswordReset}
              error={passwordResetError}
              disabled={isSubmittingPasswordReset}
              onCancel={() => this.setState({ showPasswordReset: false, passwordResetError: null })}
              onSubmit={this.handlePasswordResetRequest}
              email={profile.email}
            />
          )}
          {showImport && (
            <ImportIntakeRecordDialog
              open={showImport}
              error={importError}
              disabled={isImportingRecord}
              onCancel={() => this.setState({ showImport: false, importError: null })}
              onSubmit={this.handleImportRequest}
            />
          )}
        </main>
      </div>
    );
  }
}

AuthenticatedRoutes.propTypes = {
  width: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string,
  apiVersion: PropTypes.string,
  shouldShowSideDrawer: PropTypes.bool.isRequired,
  showSideDrawer: PropTypes.func.isRequired,
  hideSideDrawer: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  getApiVersion: PropTypes.func.isRequired,
  profile: PropTypes.object,
  isImportingRecord: PropTypes.bool.isRequired,
  importIntakeRecord: PropTypes.func.isRequired,
  getIntakeSummaries: PropTypes.func.isRequired,
  getRelocationConsultants: PropTypes.func.isRequired,
  getFirebaseToken: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const { profile } = state.auth;
  const { title, shouldShowSideDrawer, apiVersion } = state.layout;
  const { isImportingRecord } = state.intake;
  const { currentAuthId, authorizationDetails } = state.authorizations;
  return {
    title,
    shouldShowSideDrawer,
    apiVersion,
    profile,
    isImportingRecord,
    currentAuthId,
    authorizationDetails
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth(),
  withRouter,
  connect(mapStateToProps, {
    showSideDrawer,
    hideSideDrawer,
    showToast,
    getUserProfile,
    importIntakeRecord,
    getIntakeSummaries,
    getRelocationConsultants,
    getApiVersion,
    getFirebaseToken
  })
)(AuthenticatedRoutes);
