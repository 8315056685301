/* eslint-disable react-hooks/exhaustive-deps */
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useEffect, useState } from 'react';

import {
  Box, Card, CardContent, Grid, MenuItem, Paper, Select, TextField, Typography,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import { updateVoucherSummary } from 'modules/expenses/expenses.actions';
import { formatDateForInput } from 'utilities/common';
import { formatDateForField } from './utils';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));

const RecurringPaymentCard = (props) => {
  const { voucherInfo, isReadOnly, updateVoucherInfo } = props;

  const [calculatedEnd, setCalculatedEnd] = useState(null);
  const [paymentDates, setPaymentDates] = useState([]);
  
  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const frequencyOptions = [
    { title: 'Weekly', id: 1, periodsPerYear: 52 },
    { title: 'Bi-Weekly', id: 2, periodsPerYear: 26 },
    { title: 'Semi-Monthly', id: 3, periodsPerYear: 24 },
    { title: 'Monthly', id: 4, periodsPerYear: 12 },
    { title: 'Quarterly', id: 5, periodsPerYear: 4 },
    { title: 'Semi-Annually', id: 7, periodsPerYear: 2 },
    { title: 'Annually', id: 8, periodsPerYear: 1 },
  ];

  useEffect(() => {
    if (voucherInfo.frequency && voucherInfo.startDate && voucherInfo.numPayments) {
        setPaymentDates([]);

        let tempDate = new Date(voucherInfo.startDate);

        const periodsPerYear = frequencyOptions.find((fo) => fo.id === voucherInfo.frequency).periodsPerYear;
        const daysPerPeriod = Math.round(365 / periodsPerYear);
        const totalDaysToAdd = parseInt(voucherInfo.numPayments - 1) * daysPerPeriod; 

        tempDate.setDate(tempDate.getDate() + totalDaysToAdd + (voucherInfo.startDate.includes('T') ? 0 : 1));
        setCalculatedEnd(formatDateForField(tempDate));

        let runningLastDate = new Date(voucherInfo.startDate);
        runningLastDate.setDate(runningLastDate.getDate());

        let initialDate = voucherInfo.startDate.includes('T') ? new Date(voucherInfo.startDate) : new Date(`${voucherInfo.startDate}T00:00:00`);
        let tempPaymentDates = [{ date: initialDate, status: 3 }];
        for (var i = 0; i < voucherInfo.numPayments - 1; i++) {
            let tempPaymentDate = new Date(runningLastDate);
            tempPaymentDate.setDate(runningLastDate.getDate() + daysPerPeriod + ((i === 0 && !voucherInfo.startDate.includes('T')) ? 1 : 0) - ((i === 0 && periodsPerYear === 2) ? 1 : 0));
            tempPaymentDates.push({ date: tempPaymentDate, status: (i === 0) ? 2 : 1});
            runningLastDate = tempPaymentDate;
        }

        setPaymentDates(tempPaymentDates);
    }
  }, [voucherInfo.frequency, voucherInfo.startDate, voucherInfo.numPayments]);

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ marginTop: '10px', marginRight: '10px' }}>
        <Box>
          <CardContent sx={{ padding: '10px', marginLeft: '10px', paddingTop: '0px' }}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
                    <Box sx={{marginRight: '20px'}}>
                        <Box>
                            <Typography variant="body2" >
                                Frequency
                            </Typography>
                        </Box>
                        <Box>
                            <Select
                                labelId={'frequencyOption'}
                                key={'frequencyOption'}
                                label={''}
                                name={'frequencyOption'}
                                value={voucherInfo.frequency}
                                sx={{ maxWidth: '350px', minWidth: '180px' }}
                                disabled={isReadOnly}
                                onChange={(e) =>
                                    updateVoucherInfo({ ...voucherInfo, frequency: e.target.value })
                                }
                            >
                                {frequencyOptions.map((opt) => (
                                    <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                        {opt.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                    <Box sx={{marginRight: '20px'}}>
                        <Box>
                            <Typography variant="body2" >
                                Start Date
                            </Typography>
                        </Box>
                        <TextField
                            key={'startDate'}
                            label={''}
                            name={'startDate'}
                            required={false}
                            disabled={false}
                            value={formatDateForInput(voucherInfo.startDate)}
                            sx={{ maxWidth: '360px' }}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                updateVoucherInfo({ ...voucherInfo, startDate: e.target.value, entryDate: e.target.value, invoiceDate: e.target.value, dueDate: e.target.value, payrollDate: e.target.value })
                            }
                        />
                    </Box>
                    <Box sx={{marginRight: '20px'}}>
                        <Box>
                            <Typography variant="body2" >
                                Number of Payments
                            </Typography>
                        </Box>
                        <TextField
                            label=""
                            name="numPayments"
                            required={true}
                            value={voucherInfo.numPayments}
                            sx={{ maxWidth: '170px' }}
                            type="number"
                            onChange={e => updateVoucherInfo({ ...voucherInfo, numPayments: e.target.value })}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                    <Box>
                        <Box>
                            <Typography variant="body2" >
                                End Date
                            </Typography>
                        </Box>
                        <TextField
                            key={'endDate'}
                            label={''}
                            name={'endDate'}
                            required={false}
                            disabled={true}
                            value={calculatedEnd}
                            sx={{ maxWidth: '360px' }}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            
                        />
                    </Box>
                </Box>
                <Box sx={{paddingTop: '20px'}}>
                    <Paper style={{minWidth: '600px', maxWidth: '600px',  maxHeight: '100px', overflowY: 'scroll'}}>
                        <Grid container>
                            {paymentDates.map((payment) => {
                                return (
                                    <Grid item md={6}>
                                        <Box sx={{padding: '10px', color: payment.status === 2 ? 'green' : payment.status === 3 ? '#41B7C0' : undefined, minWidth: '35%'}}>{`Payment ${payment.date.toLocaleDateString()} ${payment.status === 2 ? '(Next)' : payment.status === 3 ? '(Paid)' : '(Future)'}`}</Box>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Paper>
                </Box>
            </Box>
            {/* <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <Box sx={{marginRight: '10px'}}>
                    {voucherInfo.cancelRecurring && (
                        <Box sx={{marginTop: '8px'}}>
                            <Typography variant="body2" >Cancelled recurring payment on {voucherInfo.cancelRecurringDate.toLocaleDateString()}.</Typography>
                        </Box>
                    )}
                    {!voucherInfo.cancelRecurring && (
                        <Button onClick={() => updateVoucherInfo({ ...voucherInfo, cancelRecurring: true, cancelRecurringDate: new Date()})} variant="outlined" color="primary">Cancel Recurring Payment</Button>
                    )}
                </Box>
                {voucherInfo.submitToReview && (
                    <Box sx={{marginTop: '8px'}}>
                        <Typography variant="body2" >Voucher submitted to EA Review.</Typography>
                    </Box>
                )}
                {!voucherInfo.submitToReview && (
                    <Button onClick={() => updateVoucherInfo({ ...voucherInfo, submitToReview: true})} variant="contained" color="primary">Submit to EA Review</Button>
                )}
            </Box> */}
                
                
          </CardContent>
        </Box>
      </Card>
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    updateVoucherSummary,
    showToast,
  }),
)(RecurringPaymentCard);