import {
  GET_AUTH_CLIENT_POLICIES_FAILURE,
  GET_AUTH_DATE_SUMMARY_FAILURE,
  GET_AUTH_SERVICES_FAILURE,
  GET_AUTH_SUMMARY_FAILURE,
  GET_AUTH_TEAM_FAILURE,
  GET_COMMUNICATION_INFO_FAILURE,
  GET_DEPARTURE_ADDRESS_FAILURE,
  GET_DESTINATION_ADDRESS_FAILURE,
  GET_EMPLOYEE_INFO_FAILURE,
  GET_FAMILY_FAILURE,
  GET_FAMILY_PET_FAILURE,
  GET_USER_SETTINGS_FAILURE,
  GET_FINANCE_AND_TAX_FAILURE,
  GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_FAILURE
} from 'modules/authorizations/store/types/authorizationTypes';
import {
  GET_ALL_CANDIDATE_TRIPS_FAILURE,
  GET_CANDIDATE_SERVICES_FAILURE,
  GET_COST_ESTIMATES_FAILURE,
  GET_INTERN_SERVICES_FAILURE
} from 'modules/authorizations/store/types/canidateTripTypes';
import {
  GET_ALL_BROKER_AGENTS_FAILURE,
  GET_EXPENSE_CODES_FAILURE,
  GET_INSPECTORS_FAILURE,
  GET_TITLE_REP_FAILURE,
  GET_VENDOR_CONTACT_FAILURE,
  GET_VENDORS_FAILURE
} from 'modules/authorizations/store/types/dataManagementTypes';
import {
  GET_AUTH_HOUSE_HUNTING_LIST_FAILURE,
  GET_AUTH_OFFER_HISTORY_LIST_FAILURE
} from 'modules/authorizations/store/types/destinationServiceTypes';
import {
  GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE,
  GET_ASSIGNMENT_EXTENSIONS_FAILURE,
  GET_ASSIGNMENT_TRIPS_FAILURE,
  GET_GLOBAL_DESTINATION_SERVICES_FAILURE,
  GET_GLOBAL_INFO_FAILURE,
  GET_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
  GET_GLOBAL_LANGUAGE_TRAINING_FAILURE
} from 'modules/authorizations/store/types/globalTypes';
import {
  GET_ALL_HOMESALE_RESALE_OFFER_FAILURE,
  GET_APPRAISALS_FAILURE,
  GET_EQUITY_FAILURE,
  GET_HOMESALE_INFO_FAILURE,
  GET_INSPECTIONS_FAILURE,
  GET_LISTINGS_FAILURE
} from 'modules/authorizations/store/types/homesaleTypes';
import { GET_HHG_SEGMENTS_FAILURE, GET_HHG_VEHICLES_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { GET_EXPENSE_LINES_BY_AUTH_FAILURE, GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE } from 'modules/expenses/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { GET_AUTH_TAX_ASSISTANCE_FAILURE, GET_TAX_POLICIES_BY_CLIENT_FAILURE } from '../store/types/taxAssistanceTypes';
import { GET_COMMENT_AND_NOTES_FAILURE } from '../store/types/commentsAndNotesTypes';
import { GET_ATTACHMENT_FAILURE } from '../store/types/attachmentTypes';

export const fetchBrokerAgents = async (brokerAgents, props) => {
  if (!brokerAgents || brokerAgents.length === 0) {
    try {
      const brokerAgentsResp = await props.getBrokerAgents();
      if (brokerAgentsResp && brokerAgentsResp.type === GET_ALL_BROKER_AGENTS_FAILURE) {
        props.showToast('Failed to retrieve broker agents info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return null;
      }
      return brokerAgentsResp;
    } catch (error) {
      console.error('Error fetching broker agents:', error);
      props.showToast('An error occurred while retrieving broker agents.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      throw error;
    }
  }
  return brokerAgents;
};

export const fetchInspectors = async (inspectors, props) => {
  if (!inspectors || inspectors.length === 0) {
    try {
      const inspectorsResp = await props.getInspectors();
      if (inspectorsResp && inspectorsResp.type === GET_INSPECTORS_FAILURE) {
        props.showToast('Failed to retrieve inspectors info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return null;
      }
      return inspectorsResp;
    } catch (error) {
      console.error('Error fetching inspectors:', error);
      props.showToast('An error occurred while retrieving inspectors.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      throw error;
    }
  }
  return inspectors;
};

export const fetchVendors = async (vendors, props) => {
  if (!vendors || vendors.length === 0) {
    try {
      const vendorsResp = await props.getVendors();

      if (vendorsResp && vendorsResp.type === GET_VENDORS_FAILURE) {
        props.showToast('Failed to retrieve vendor info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return null;
      }
      return vendorsResp;
    } catch (error) {
      console.error('Error fetching vendors:', error);
      props.showToast('An error occurred while retrieving vendors.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      throw error;
    }
  }
  return vendors;
};

export const fetchVendorContacts = async (vendorContacts, props) => {
  if (!vendorContacts || vendorContacts.length === 0) {
    try {
      const vendorContactsResp = await props.getVendorContacts();
      if (vendorContactsResp && vendorContactsResp.type === GET_VENDOR_CONTACT_FAILURE) {
        props.showToast('Failed to retrieve vendor contact info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return null;
      }
      return vendorContactsResp;
    } catch (error) {
      console.error('Error fetching vendor contacts:', error);
      props.showToast('An error occurred while retrieving vendor contacts.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      throw error;
    }
  }
  return vendorContacts;
};

export const fetchExpenseCodes = async (expenseCodes, props) => {
  if (!expenseCodes || expenseCodes.length === 0) {
    try {
      const expenseCodesResp = await props.getExpenseCodes();
      if (expenseCodesResp.type === GET_EXPENSE_CODES_FAILURE) {
        props.showToast('Failed to retrieve expense codes, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return null;
      }
      return expenseCodesResp;
    } catch (error) {
      console.error('Error fetching expense codes:', error);
      props.showToast('An error occurred while retrieving expense codes.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      throw error;
    }
  }
  return expenseCodes;
};

export const fetchTitleReps = async (titleReps, props) => {
  if (!titleReps || titleReps.length === 0) {
    try {
      const titleRepsResp = await props.getTitleReps();
      if (titleRepsResp.type === GET_TITLE_REP_FAILURE) {
        props.showToast('Failed to retrieve title reps, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return null;
      }
      return titleRepsResp;
    } catch (error) {
      console.error('Error fetching title reps:', error);
      props.showToast('An error occurred while retrieving title reps.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      throw error;
    }
  }
  return titleReps;
};

export const fetchAuthDetails = async (details, id, props) => {
  try {
    if (!details || !details?.authDetails) {
      const [
        authSummaryResp,
        employeeInfoResp,
        authDateSummaryResp,
        communicationInfoResp,
        departureResp,
        destinationResp,
        authClientPoliciesResp,
        teamResp,
        authServiceResp,
        userSettingsResp,
        familyMembersResp,
        petsResp,
        financeAndTaxResp
      ] = await Promise.all([
        props.getAuthorizationSummary(id),
        props.getEmployeeInfo(id),
        props.getAuthDateSummary(id),
        props.getCommunicationInfo(id),
        props.getDepartureAddress(id),
        props.getDestinationAddress(id),
        props.getAuthClientPolicies(id),
        props.getTeam(id),
        props.getServices(id),
        props.getUserSettings(id),
        props.getFamilyMembers(id),
        props.getFamilyPets(id),
        props.getFinanceAndTax(id)
      ]);

      if (authSummaryResp.type === GET_AUTH_SUMMARY_FAILURE) {
        props.showToast('Failed to retrieve authorization info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (employeeInfoResp.type === GET_EMPLOYEE_INFO_FAILURE) {
        props.showToast('Failed to retrieve employee info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (authDateSummaryResp.type === GET_AUTH_DATE_SUMMARY_FAILURE) {
        props.showToast('Failed to retrieve auth summary info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (communicationInfoResp.type === GET_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to retrieve employee communication info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (departureResp.type === GET_DEPARTURE_ADDRESS_FAILURE) {
        props.showToast('Failed to retrieve departure address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (destinationResp.type === GET_DESTINATION_ADDRESS_FAILURE) {
        props.showToast('Failed to retrieve destination address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
        props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (teamResp.type === GET_AUTH_TEAM_FAILURE) {
        props.showToast('Failed to retrieve auth team, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (authServiceResp.type === GET_AUTH_SERVICES_FAILURE) {
        props.showToast('Failed to retrieve auth services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (userSettingsResp.type === GET_USER_SETTINGS_FAILURE) {
        props.showToast('Failed to retrieve employee settings info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (familyMembersResp.type === GET_FAMILY_FAILURE) {
        props.showToast('Failed to get family members, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (petsResp.type === GET_FAMILY_PET_FAILURE) {
        props.showToast('Failed to retrieve pet info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (financeAndTaxResp.type === GET_FINANCE_AND_TAX_FAILURE) {
        props.showToast('Failed to retrieve finance and tax info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
    }
  } catch (error) {
    console.error('Error fetching authorization details:', error);
    props.showToast('An unexpected error occurred. Please try again later.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
  }
};

export const fetchAuthCanidateDetails = async (details, id, props) => {
  try {
    if (!details || !details?.authCanidateTrips) {
      const [costEstimateResp, candidateResp, internResp, candidateTripsResp] = await Promise.all([
        props.getCostEstimates(id),
        props.getCandidateServices(id),
        props.getInternServices(id),
        props.getCandidateTrips(id)
      ]);

      if (costEstimateResp.type === GET_COST_ESTIMATES_FAILURE) {
        props.showToast('Failed to retrieve cost estimates info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (candidateResp.type === GET_CANDIDATE_SERVICES_FAILURE) {
        props.showToast('Failed to retrieve candidate services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (internResp.type === GET_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to retrieve intern services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (candidateTripsResp.type === GET_ALL_CANDIDATE_TRIPS_FAILURE) {
        props.showToast('Failed to retrieve candidate trips, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
    }
  } catch (error) {
    console.error('Error fetching canidate details:', error);
    props.showToast('An unexpected error occurred. Please try again later.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
  }
};

export const fetchAuthDestinationDetails = async (details, id, props) => {
  try {
    if (!details || !details?.authDestinationService) {
      const [destinationServicesResp, houseHuntingResp, offerHistoryResp] = await Promise.all([
        props.getAuthDestinationServices(id),
        props.getHouseHuntingList(id),
        props.getOfferHistoryList(id)
      ]);

      if (destinationServicesResp.type === GET_AUTH_OFFER_HISTORY_LIST_FAILURE) {
        props.showToast('Failed to retrieve all offer history records, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
      if (houseHuntingResp.type === GET_AUTH_HOUSE_HUNTING_LIST_FAILURE) {
        props.showToast('Failed to retrieve all house hunting trips, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (offerHistoryResp.type === GET_AUTH_OFFER_HISTORY_LIST_FAILURE) {
        props.showToast('Failed to retrieve all offer history records, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
    }
  } catch (error) {
    console.error('Error fetching destination details:', error);
    props.showToast('An unexpected error occurred. Please try again later.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
  }
};

export const fetchAuthExpensesDetails = async (details, id, props) => {
  try {
    if (!details || !details?.authExpenses) {
      const [expenseSummariesResp, expenseLineItemsResp] = await Promise.all([
        props.getExpenseSummariesByAuth(id),
        props.getExpenseLinesByAuthId(id)
      ]);

      if (expenseSummariesResp.type === GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE) {
        props.showToast('Failed to retrieve submitted expenses, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (expenseLineItemsResp.type === GET_EXPENSE_LINES_BY_AUTH_FAILURE) {
        props.showToast('Failed to retrieve expense line items, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
    }
  } catch (error) {
    console.error('Error fetching expenses details:', error);
    props.showToast('An unexpected error occurred. Please try again later.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
  }
};

export const fetchAuthGlobalDetails = async (details, id, props) => {
  try {
    if (!details || !details?.authGlobal) {
      const [
        globalDetailsResp,
        assignmentTripsResp,
        assignmentExtensionsResp,
        propertiesResp,
        globalDestinationResp,
        globalInternationalSchoolingResp,
        globalLanguageTrainingResp
      ] = await Promise.all([
        props.getGlobalInfo(id),
        props.getAssignmentTrips(id),
        props.getAssignmentExtensions(id),
        props.getGlobalHomeServiceProperties(id),
        props.getGlobalDestinationServices(id),
        props.getGlobalInternationalSchooling(id),
        props.getGlobalLanguageTraining(id)
      ]);

      if (globalDetailsResp.type === GET_GLOBAL_INFO_FAILURE) {
        props.showToast('Failed to retrieve global service info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (assignmentTripsResp.type === GET_ASSIGNMENT_TRIPS_FAILURE) {
        props.showToast('Failed to retrieve global trips info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (assignmentExtensionsResp.type === GET_ASSIGNMENT_EXTENSIONS_FAILURE) {
        props.showToast('Failed to retrieve global extensions info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (propertiesResp.type === GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE) {
        props.showToast('Failed to retrieve global home service properties info, please try again', {
          severity: TOAST_MESSAGE_SEVERITY_ERROR
        });
        return;
      }

      if (globalDestinationResp.type === GET_GLOBAL_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed fetching global destination services, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (globalInternationalSchoolingResp.type === GET_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE) {
        props.showToast('Failed fetching global international schooling, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (globalLanguageTrainingResp.type === GET_GLOBAL_LANGUAGE_TRAINING_FAILURE) {
        props.showToast('Failed fetching global language training, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
    }
  } catch (error) {
    console.error('Error fetching global details:', error);
    props.showToast('An unexpected error occurred. Please try again later.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
  }
};

export const fetchAuthHouseholdGoodsDetails = async (details, id, props) => {
  try {
    if (!details || !details?.authHouseHoldGoods) {
      const [hhgSegmentsResp, hhgVehiclesResp] = await Promise.all([props.getHhgSegments(id), props.getHhgVehicles(id)]);

      if (hhgVehiclesResp.type === GET_HHG_VEHICLES_FAILURE) {
        props.showToast('Failed to retrieve hhg vehicles info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (hhgSegmentsResp.type === GET_HHG_SEGMENTS_FAILURE) {
        props.showToast('Failed to retrieve hhg segments info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      } else {
        return hhgSegmentsResp;
      }
    }
  } catch (error) {
    console.error('Error fetching household goods details:', error);
    props.showToast('An unexpected error occurred. Please try again later.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
  }
};

export const fetchAuthHomesaleDetails = async (details, id, props) => {
  try {
    if (!details || !details?.authHomesale) {
      const [homesaleResp, appraisalsResp, homesaleInspectionsResp, listingsResp, resaleResp, equitiesResp] = await Promise.all([
        props.getHomesaleInfo(id),
        props.getAllAppraisals(id),
        props.getInspections(id),
        props.getAllListings(id),
        props.getHomesaleResaleOffers(id),
        props.getEquity(id)
      ]);

      if (homesaleResp.type === GET_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to retrieve homesale info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (appraisalsResp.type === GET_APPRAISALS_FAILURE) {
        props.showToast('Failed to retrieve homesale appraisals, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (homesaleInspectionsResp.type === GET_INSPECTIONS_FAILURE) {
        props.showToast('Failed to retrieve homesale inspections, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (listingsResp.type === GET_LISTINGS_FAILURE) {
        props.showToast('Failed to retrieve listings information, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (resaleResp.type === GET_ALL_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to retrieve resale offers, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }

      if (equitiesResp.type === GET_EQUITY_FAILURE) {
        props.showToast('Failed to retrieve equities information, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
    }
  } catch (error) {
    console.error('Error fetching homesale details:', error);
    props.showToast('An unexpected error occurred. Please try again later.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
  }
};

export const fetchTaxAssistanceDetails = async (details, clientInfo, id, props) => {
  if ((!details || !details?.authTaxAssistance) && clientInfo) {
    const [taxAssistanceResp, taxPoliciesResp] = await Promise.all([
      props.getAuthTaxAssistance(id),
      props.getTaxPoliciesByClientId(clientInfo?.id)
    ]);

    if (taxAssistanceResp.type === GET_AUTH_TAX_ASSISTANCE_FAILURE) {
      props.showToast('Failed to retrieve tax policies, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }

    if (taxPoliciesResp.type === GET_TAX_POLICIES_BY_CLIENT_FAILURE) {
      props.showToast('Failed to retrieve client tax policies, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    }
  }
};

export const fetchCommentsAndNotes = async (details, id, props) => {
  try {
    if (!details || !details?.commentsAndNotes) {
      const [commentsAndNotesResp] = await Promise.all([props.getCommentsAndNotes(id)]);

      if (commentsAndNotesResp.type === GET_COMMENT_AND_NOTES_FAILURE) {
        props.showToast('Failed to retrieve authorization comments and notes, please try again', {
          severity: TOAST_MESSAGE_SEVERITY_ERROR
        });
        return;
      }
    }
  } catch (error) {
    console.error('Error fetching authorization comments and notes:', error);
    props.showToast('An unexpected error occurred. Please try again later.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
  }
};

export const fetchAttachment = async (fileId, props) => {
  try {
    const attachmentResp = await props.getAttachment(fileId);
    console.log(attachmentResp);
    if (attachmentResp.type === GET_ATTACHMENT_FAILURE) {
      props.showToast('Failed to retrieve attatchment, please try again', {
        severity: TOAST_MESSAGE_SEVERITY_ERROR
      });
      return false;
    }
    console.log(attachmentResp);
    return attachmentResp.response;
  } catch (error) {
    console.error('Error fetching attachment:', error);
    props.showToast('An unexpected error occurred. Please try again later.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    return false;
  }
};

export const fetchClientPoliciesByClient = async (policyOptionsList, moveTrackClientId, props) => {
  try {
    if (!policyOptionsList && moveTrackClientId) {
      const [clientPoliciesByClientResp] = await Promise.all([props.getAuthClientPoliciesListByClient(moveTrackClientId)]);

      if (clientPoliciesByClientResp.type === GET_AUTH_LIST_CLIENT_POLICIES_BY_CLIENT_FAILURE) {
        props.showToast('Failed to retrieve client policies by client info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
    }
  } catch (error) {
    console.error('Error fetching authorization details:', error);
    props.showToast('An unexpected error occurred. Please try again later.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
  }
};
